import React from "react";
import Layout from "../components/layout";
import Wrapper from "../components/wrapper/Wrapper";
import { Link } from "gatsby";
const Error = () => {
  return (
    <Layout>
      <Wrapper>
        <div
          style={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h2>申し訳ございません。何か問題が発生しました。</h2>
          <Link to="/" className="link">
            ホームに戻る。
          </Link>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default Error;
